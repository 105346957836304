import { graphql } from 'gatsby'
import * as React from 'react'
import FeatureProjects from '../components/featureProjects'
import Header from '../components/header'
import Layout from '../components/layout'
import NextPage from '../components/nextPage'
import OrderedList from '../components/orderedList'
import ScrollingBanner from '../components/scrollingBanner'
import SEO from '../components/seo'

class DevelopmentsPage extends React.Component {
	render() {
		const data = this.props.data.allDatoCmsOurDevelopment.edges[0].node
		const projects = this.props.data.feature.edges
		const all = this.props.data.all.edges
		const seo = data.seo
		return (
			<Layout>
				<SEO title={seo?.title || 'Developments at Siera'} description={seo?.description} image={seo?.image?.url} />
				<Header page='Developments' className='is-dark' />
				<ScrollingBanner mobile='true' videoClass='filter-darken' video={data.videoUrl} title='Our Developments' />
				<div className='m-bg-white'>
					<div className='spacer m-hide'></div>
					<div className='spacer m-hide'></div>
					<FeatureProjects left={data.leftProject} right={data.rightProject} projects={projects} hide='hide' />
					<div className='spacer'></div>
					<div className='spacer'></div>
					<OrderedList secondRowName='Location' thirdRowName='Info' items={all} />
					<div className='spacer'></div>
				</div>
				<NextPage title='Our Story' link='/story' image={data.heroImage} />
			</Layout>
		)
	}
}

export default DevelopmentsPage

export const query = graphql`
	query Developments {
		allDatoCmsOurDevelopment {
			edges {
				node {
					leftProject {
						title
						featureImage {
							gatsbyImageData
						}
						location
					}
					rightProject {
						title
						featureImage {
							gatsbyImageData
						}
						location
					}
					id
					videoUrl
					heroImage {
						gatsbyImageData(placeholder: DOMINANT_COLOR)
					}
					seo {
						description
						title
						image {
							url
						}
					}
				}
			}
		}
		feature: allDatoCmsProject(filter: { featureProject: { eq: true } }, sort: { fields: date, order: DESC }) {
			edges {
				node {
					title
					featureImage {
						gatsbyImageData
					}
					location
					date
					id
				}
			}
		}

		all: allDatoCmsProject(sort: { fields: date, order: DESC }) {
			edges {
				node {
					title
					featureImage {
						gatsbyImageData
					}
					location
					info
					id
					date(formatString: "yy")
				}
			}
		}
	}
`

import React from "react"
import Link from "../components/link"
import { GatsbyImage } from "gatsby-plugin-image"
import { groupBy, kebabCase } from 'lodash'


function activateItem (e)  {
  document.querySelector('.ordered--item.active').classList.remove('active');
  var item = e.target;
  item.classList.add('active');
  var number = item.getAttribute('data-item');
  document.querySelector('.item--image.active').classList.remove('active');
  document.querySelector('.item--image[data-image="' + number + '"]').classList.add('active')
}


const OrderedList = ({ items, secondRowName, thirdRowName, secondClassName, target }) => {

  const orderedYears = Object.entries(groupBy(items, 'node.date')).reverse();


  const lineYears = orderedYears.map(([year, projects], index) => {

    return (
      <div className={(index !== 0) ? 'mt40 mb40' : ''} key={index}>
        <div className= 'bb1 pb10'>
          {year}
        </div>
        <LineItems year={year} nextYearIndex={(index + 1 === orderedYears.length ? 0 : index + 1 )} target={target} secondClassName={secondClassName} projects={projects} firstGroup={(index === 0)} activateItem={activateItem} />
      </div>
    )
  })

  const images = items.map((item, index) => {
    return (
      <div key={index} className={"bg-image item--image " + (index === 0 ? 'active' : '')} data-image={item.node.id}>
        <GatsbyImage className="bg-image" image={item.node.featureImage?.gatsbyImageData} alt={item.node.title} />
      </div>
    )
  })

  function LineItems  ({ projects, activateItem, firstGroup, link, secondClassName, target,nextYearIndex,year }) {
   
    return projects.map((item, index) => {
    
      
      return (
        <Link  target={target} to={item?.node?.link ? item.node.link : `/projects/${kebabCase(item.node.title.toLowerCase())}`} data-item={item.node.id} key={index} className={"ordered--item w-100 link " + (index === 0 && firstGroup ? 'active' : '')} onMouseOver={activateItem}>
          <div className="bb1 pt15 pb15 flex uppercase inner">
            <div className="flex w-100-120 m-100 ">
              <p className='m0 w-30 m-w-40 flex-grow-1'>{item.node.title}</p>
              <p className='m0 w-30 m-w-60 m-w-a  '>{item.node.location}</p>
              <p className={'m0 w-40 m-hide ' + secondClassName}>{item.node.info}</p>
            </div>
            <div className="read-more ml10 m-hide" >
              <p className='m0 button small flex' style={{ 'width': '120px' }}>Read More</p>
            </div>
          </div>
        </Link>
      )
    })
  }



  return (
    <div className="ordered--list p40">
      <div className="max-1400 ma flex">
        <div className="image-column pt10 m-hide">
          <div className="max-180 sticky--top">
            <div className="ratio-2-1 bg-grey">
              {images}
            </div>
          </div>
        </div>
        <div className="ordered-list w-100 pl40 m-p0">
          {lineYears}  
        </div>
      </div>
    </div>
  )
}

export default OrderedList
